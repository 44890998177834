import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../../../components/Layout"
import HubspotForm from "react-hubspot-form"

export default function GetADemo() {
  return (
    <Layout
      title="Webinar: Proactive AI Support For Enhanced Customer Support Automation"
      keywords="IrisAgent, webinar, incidents, tickets"
      description="Discover how a Product-Led growth strategy is elevated through AI-powered support automation. Learn to prevent churn, & enhance CSAT by using real-time product insights."
    >
      <Helmet>
        <script
          charSet="utf-8"
          src="https://js.hsforms.net/forms/shell.js"
        ></script>
        <link rel="canonical" href="https://irisagent.com/csc-webinar/watch-webinar/" />
      </Helmet>

      <section className="inner-page" style={{ margin: "2% 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <h1>Watch Webinar Recording!</h1>
                <br />
                <p>

                  Learn how to Increase renewals and prevent at-risk accounts with proactive AI-powered support automation
                  <br />
                  <br />
                  A Product-Led growth (PLG) strategy puts the product at the forefront of the customer journey to drive conversion, adoption, retention, and expansion. In order to achieve the aforementioned, customer success, customer support and product functions need to deliver the best possible customer experience.
                  <br />
                  <br />
                  Customer Success teams are often in the cross-hairs for poor customer experiences, tasked with improving CSAT, driving renewals, and retention. However, accessing real-time insights into product issues, potential customer churn, and revenue impact is a blind spot for Customer Success teams. IrisAgent AI-powered Support Automation platform addresses this challenge by unlocking product insights and issues in real-time along with key revenue metrics and delivering them to Customer Success teams in real-time. Customer Success teams thus can avert at-risk accounts, focus on CSAT and maximize renewals.
                </p>
                <img
                  src="/img/webinar-bg-2.jpeg"
                  className="img-fluid"
                  alt="Schedule-demo"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                {/* <HubspotForm
                  portalId='7494256'
                  formId='29f4e374-b150-48db-9a15-668eeac19625'
                  loading={<div>Loading...</div>}
                /> */}
                <HubspotForm
                  portalId="7494256"
                  formId="9ad8978f-c351-4609-9708-2754eb04c955"
                  loading={<div>Loading...</div>}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
